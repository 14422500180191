import { useForm } from 'react-hook-form'

import { Navigation, Input, If } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import usePropertyDataCustomize from './hooks'

const Customize = ({ pageContext }) => {
  const initialValues = {
    customize: ''
  }

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = formProps

  const { onSubmit, submitLoading } = usePropertyDataCustomize()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={submitLoading}
        renderIf={
          <>
            <Shimmer margin="8px 0 32px 0" widthDesktop="52%" height="32px" />
            <Shimmer margin="0 0 20px 0" height="70px" />
            <Shimmer
              margin="360px 0 0 0"
              marginDesktop="300px 0 0 0"
              widthDesktop="50%"
              height="50px"
            />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">Qual é o valor do seu imóvel?</h1>
            <form
              className="form custom-value"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input.Currency
                label="Informe o valor"
                id="customize"
                name="customize"
                defaultValue={0}
                formProps={formProps}
                rules={{
                  min: 1
                }}
                control={control}
              />
              <Navigation
                nextStep
                submit={isValid}
                nextButtonText="Avançar"
                nextButtonId="property-data-customize-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default Customize
